body,
* {
  padding: 0;
  margin: 0;
  font-family: 'Roboto Flex', sans-serif;
  box-sizing: border-box;
  text-align: center;
}

html {
  overflow: auto;
  // overflow: hidden;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #293138;
    /* Фон скролбара */
  }

  &::-webkit-scrollbar-thumb {
    background: #575D68;
    /* Кольоровий покажчик */
    border-radius: 5px;
  }
}

.container {
  min-height: 100vh;
  max-width: auto;
  padding: 28px 20px;
  display: flex;
  flex-flow: column;
  align-items: center;


  &.questionContainer {
    width: 100%;
    max-width: 874px;
    display: flex;
    flex-flow: column;
    align-items: start;
    margin: 7px auto;
    border: 1px solid #D8DCE5;
    border-radius: 15px;
    font-size: 0.1em;
    padding: 10px 15px;

  }

  &.questionContainer * {
    text-align: left !important;
    font-size: 0.85rem;
  }

  &.linksContainer {
    display: flex;
    flex-flow: row nowrap;
    gap: 10px;
    align-items: flex-start;
    margin: 10px 0;
    padding: 10px;
  }

  &.linksContainer * {
    text-align: left !important;
    font-size: 0.9rem;
  }

  &.loader {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
  }

  .scout-title {
    font-weight: 700;
    letter-spacing: 1.6px;
    line-height: normal;
  }

  .card {
    width: 100%;
    max-width: 874px;
    border-radius: 30px;
    display: flex;
    flex-flow: column;
    align-items: center;


    margin: 20px 0;
    padding: 25px;

    .star-container {
      display: flex;
      height: 38px;
      margin-bottom: 11px;
      margin-top: 11px;
      justify-content: center;

      .star {
        cursor: pointer;

        &:not(:last-child) {
          margin-right: 14px;
        }
      }
    }

    .surveyContainer {
      width: 100%;

    }

    .rate-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      width: 100%;
      max-width: 360px;
      height: 60px;
      border-radius: 40px;
      letter-spacing: 1.44px;
      font-weight: 700;
      font-size: 18px;
      color: #15223A;
      margin-top: 16px;
      overflow: hidden;
    }

    &.company-card {

      .company-logo {
        width: 109px;
      }

      .company-name {
        margin: 12px 0 8px;
      }

      .company-description {
        font-size: 14px;
      }

      .company-location {
        margin: 10px 60px 1px;
      }

      .star-container-wrap {
        height: 0;
        margin-top: 0;
        margin-bottom: 0;
        overflow: hidden;
        transition: .5s margin-top, .5s height;
        pointer-events: none;

        svg {
          width: 24px;
          height: 24px;
        }

        &.expanded {
          height: 34px;
          margin-top: 5px;
        }
      }
    }

    &.rating-card {
      line-height: 34px;
      display: flex;
      align-items: center;
      justify-content: center;

      .rating-title {
        margin: 0 0 7px;
      }

      .rating-container {
        display: flex;
        align-items: center;



        p {
          font-size: 20px;
          font-weight: 600;
        }

        .selected-rating {
          font-size: 48px;
          line-height: normal;
          margin-right: 9px;
        }
      }
    }



    &.high-rate-card {
      h2 {
        font-weight: 900;
        line-height: 37.5px;
      }

      .high-rate-text {
        margin-bottom: 5px;
      }

      p,
      a {
        font-size: 18px;
      }

      .socials-container {
        display: flex;
        min-width: 304px;
        flex-wrap: wrap;
        justify-content: center;

        .social-button {
          cursor: pointer;
          flex-grow: 0;
          flex-shrink: 0;
          margin: 0 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 304px;
          height: 60px;
          border: 1px solid #D8DCE5;
          border-radius: 40px;
          background: #FFF;
          box-shadow: 0 10px 20px 0 rgba(21, 34, 58, 0.10);
          margin-top: 15px;
          text-decoration: none;

          &:not(:last-child) {
            margin-bottom: 15px;
          }

          img {
            max-height: 30px;
            max-width: 30px;
            margin-right: 10px;
          }

          p,
          a {
            font-weight: 700;
            color: #15223A;
          }
        }
      }
    }

    &.low-rate-card {
      h2 {
        font-weight: 1000;
      }

      p {
        max-width: 100%;
      }

      .comment {
        margin: 20px 0;
        max-width: 522px;
        outline: none;
        width: 100%;
        height: 128px;
        resize: none;
        padding: 19px 24px 51px 24px;
        align-items: flex-start;
        border-radius: 24px;
        text-align: left;
        font-size: 18px;
      }

      .phone-number-container {
        margin-bottom: 20px;
        max-width: 522px;
        width: 100%;
        border-radius: 24px;
        align-items: flex-start;
        position: relative;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      }

      .phone-number-input {
        padding: 19px 24px;
        font-size: 18px;
        border-radius: 24px;
        outline: none;
        width: 100%;
        border: 1px solid #ccc;
        background-color: #fff;
        box-sizing: border-box;
      }

      .photo-upload-container {
        max-width: 522px;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 10px;
        
      }

      .photo-container {
        display: flex;
        align-items: center;
        border-radius: 24px;
        padding: 10px;
        margin-bottom: 5px;
        margin-right: 5px;
      }

      .phone-number-button,
      .phone-number-dropdown {
        /* Style the dropdown button to match the overall design */
        position: absolute;
        left: 10px;
        /* Example position, adjust as needed */
        top: 50%;
        transform: translateY(-50%);
        border-radius: 12px;
        /* Half of the height if you want a circular button */
      }

      .comment-actions {
        display: flex;
        align-items: center;
        width: 100%;
        max-width: 360px;

        .upload-img {
          cursor: pointer;
          height: 60px;
          aspect-ratio: 1;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 14px;
        }

        .rate-btn {
          cursor: pointer;
          width: 100%;
          margin: 0;
          outline: none;
          border: none;
        }
      }
    }

    &.manager-card {
      p {
        font-size: 18px;
        font-weight: 600;
        margin: 3px 0 20px;
      }

      img {
        width: 190px;
      }

      .phone-container {
        width: 190px;
        height: 190px;
        padding-top: 15px;
      }

      .btn-call {
        background: #38a3fd;
        border: 2px solid #38a3fd;
        border-radius: 50%;
        box-shadow: 0 8px 10px rgba(56, 163, 253, 0.3);
        cursor: pointer;
        height: 120px;
        width: 120px;
        text-align: center;
        position: fixed;
        right: 0;
        left: 0;
        margin: auto;
        // z-index: 999;
        transition: .3s;
        -webkit-animation: hoverWave linear 1s infinite;
        animation: hoverWave linear 1s infinite;
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
      }

      .btn-call__ico {
        display: flex;
        justify-content: center;
        align-items: center;
        animation: 1200ms ease 0s normal none 1 running shake;
        animation-iteration-count: infinite;
        -webkit-animation: 1200ms ease 0s normal none 1 running shake;
        -webkit-animation-iteration-count: infinite;
        color: white;
        font-size: 60px;
        padding-top: 5px;
        transition: .3s all;
      }

      .btn-call:hover {
        background-color: #fff;
      }

      .btn-call:hover .btn-call__ico {
        color: #38a3fd;
      }

      @-webkit-keyframes hoverWave {
        0% {
          box-shadow: 0 8px 10px rgba(56, 163, 253, 0.3), 0 0 0 0 rgba(56, 163, 253, 0.2), 0 0 0 0 rgba(56, 163, 253, 0.2)
        }

        40% {
          box-shadow: 0 8px 10px rgba(56, 163, 253, 0.3), 0 0 0 15px rgba(56, 163, 253, 0.2), 0 0 0 0 rgba(56, 163, 253, 0.2)
        }

        80% {
          box-shadow: 0 8px 10px rgba(56, 163, 253, 0.3), 0 0 0 30px rgba(56, 163, 253, 0), 0 0 0 26.7px rgba(56, 163, 253, 0.067)
        }

        100% {
          box-shadow: 0 8px 10px rgba(56, 163, 253, 0.3), 0 0 0 30px rgba(56, 163, 253, 0), 0 0 0 40px rgba(56, 163, 253, 0.0)
        }
      }

      @keyframes hoverWave {
        0% {
          box-shadow: 0 8px 10px rgba(56, 163, 253, 0.3), 0 0 0 0 rgba(56, 163, 253, 0.2), 0 0 0 0 rgba(56, 163, 253, 0.2)
        }

        40% {
          box-shadow: 0 8px 10px rgba(56, 163, 253, 0.3), 0 0 0 15px rgba(56, 163, 253, 0.2), 0 0 0 0 rgba(56, 163, 253, 0.2)
        }

        80% {
          box-shadow: 0 8px 10px rgba(56, 163, 253, 0.3), 0 0 0 30px rgba(56, 163, 253, 0), 0 0 0 26.7px rgba(56, 163, 253, 0.067)
        }

        100% {
          box-shadow: 0 8px 10px rgba(56, 163, 253, 0.3), 0 0 0 30px rgba(56, 163, 253, 0), 0 0 0 40px rgba(56, 163, 253, 0.0)
        }
      }

      /* animations icon */

      @keyframes shake {
        0% {
          transform: rotateZ(0deg);
          -ms-transform: rotateZ(0deg);
          -webkit-transform: rotateZ(0deg);
        }

        10% {
          transform: rotateZ(-30deg);
          -ms-transform: rotateZ(-30deg);
          -webkit-transform: rotateZ(-30deg);
        }

        20% {
          transform: rotateZ(15deg);
          -ms-transform: rotateZ(15deg);
          -webkit-transform: rotateZ(15deg);
        }

        30% {
          transform: rotateZ(-10deg);
          -ms-transform: rotateZ(-10deg);
          -webkit-transform: rotateZ(-10deg);
        }

        40% {
          transform: rotateZ(7.5deg);
          -ms-transform: rotateZ(7.5deg);
          -webkit-transform: rotateZ(7.5deg);
        }

        50% {
          transform: rotateZ(-6deg);
          -ms-transform: rotateZ(-6deg);
          -webkit-transform: rotateZ(-6deg);
        }

        60% {
          transform: rotateZ(5deg);
          -ms-transform: rotateZ(5deg);
          -webkit-transform: rotateZ(5deg);
        }

        70% {
          transform: rotateZ(-4.28571deg);
          -ms-transform: rotateZ(-4.28571deg);
          -webkit-transform: rotateZ(-4.28571deg);
        }

        80% {
          transform: rotateZ(3.75deg);
          -ms-transform: rotateZ(3.75deg);
          -webkit-transform: rotateZ(3.75deg);
        }

        90% {
          transform: rotateZ(-3.33333deg);
          -ms-transform: rotateZ(-3.33333deg);
          -webkit-transform: rotateZ(-3.33333deg);
        }

        100% {
          transform: rotateZ(0deg);
          -ms-transform: rotateZ(0deg);
          -webkit-transform: rotateZ(0deg);
        }
      }

      @-webkit-keyframes shake {
        0% {
          transform: rotateZ(0deg);
          -ms-transform: rotateZ(0deg);
          -webkit-transform: rotateZ(0deg);
        }

        10% {
          transform: rotateZ(-30deg);
          -ms-transform: rotateZ(-30deg);
          -webkit-transform: rotateZ(-30deg);
        }

        20% {
          transform: rotateZ(15deg);
          -ms-transform: rotateZ(15deg);
          -webkit-transform: rotateZ(15deg);
        }

        30% {
          transform: rotateZ(-10deg);
          -ms-transform: rotateZ(-10deg);
          -webkit-transform: rotateZ(-10deg);
        }

        40% {
          transform: rotateZ(7.5deg);
          -ms-transform: rotateZ(7.5deg);
          -webkit-transform: rotateZ(7.5deg);
        }

        50% {
          transform: rotateZ(-6deg);
          -ms-transform: rotateZ(-6deg);
          -webkit-transform: rotateZ(-6deg);
        }

        60% {
          transform: rotateZ(5deg);
          -ms-transform: rotateZ(5deg);
          -webkit-transform: rotateZ(5deg);
        }

        70% {
          transform: rotateZ(-4.28571deg);
          -ms-transform: rotateZ(-4.28571deg);
          -webkit-transform: rotateZ(-4.28571deg);
        }

        80% {
          transform: rotateZ(3.75deg);
          -ms-transform: rotateZ(3.75deg);
          -webkit-transform: rotateZ(3.75deg);
        }

        90% {
          transform: rotateZ(-3.33333deg);
          -ms-transform: rotateZ(-3.33333deg);
          -webkit-transform: rotateZ(-3.33333deg);
        }

        100% {
          transform: rotateZ(0deg);
          -ms-transform: rotateZ(0deg);
          -webkit-transform: rotateZ(0deg);
        }
      }
    }

    &.off {
      display: none;
    }

    &.on {
      display: flex;
    }
  }

  &.light-theme {
    background: #FFF;
    color: #15223A;

    .scout-title {
      color: #BABFC8;
    }

    .card {
      box-shadow: 0 0 34px 0 rgba(189, 193, 201, 0.40);

      .company-description {
        color: #AAA;
      }

      .selected-rating.inactive {
        color: #CECECE;
      }

      .rate-btn.inactive {
        cursor: not-allowed;
        background: #E4E4E4;
        color: #C2C3C6;
      }

      .comment {
        border: 1px solid #D8DCE5;
        color: #15223A;
      }
      .photo-container {
        border: 1px solid #D8DCE5;
        
      }
    }
  }



  &.dark-theme {
    width: 100%;


    background: linear-gradient(270deg, #191C1E 0%, #28343C 100%);
    color: #FFF;

    .scout-title {
      color: #575D68;
    }

    .card {
      background: #293138;
      box-shadow: 0 14px 80px 0 #1C1C1C;

      .company-description {
        color: #8B919A;
      }

      .selected-rating.inactive {
        color: #535D66;
      }

      .rate-btn.inactive {
        cursor: not-allowed;
        background: #3A434B;
        color: #5F6971;
      }

      .comment {
        background: #3A434B;
        border: none;
        color: #FFFFFF;
      }
      .photo-container {
        border: none;
        background: #3A434B;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .container {
    h2 {
      line-height: 1.2;
    }

    padding: 15px 10px;

    .card {
      margin: 10px 0;
      padding: 20px 10px;

      .company-location {
        margin: 10px 30px 1px;
      }

      .rating-title {
        font-size: 1.2em;
        padding: 0 10px;
      }

      .button {
        width: 100% !important;
      }
    }
  }
}